import React from "react"

import Layout from "../components/layout"
import nextgig from "../images/nextgig.jpg"
import SEO from "../components/seo"
import live from "../images/live.jpg"
import { Container, Row, Col } from "react-bootstrap"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <Container className="mt-5">
      <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Row className="align-items-center">
          <Col className="col-12">
            <h1>Voodoo Rock'n'Roll from Marseille</h1>
            <p>The Dirteez is a Rock'n'Roll band existing since the late 80s. They are from Marseille and have gone through many lineup changes and decades of enraged live music.</p>
            <p className="mb-0">Dirteez gigs are a deliberate electric communion, a worshipping mass where sacrifice is never in vain, a fest where rock'n'roll is celebrated with force, conviction and just what you need of novelty to get the crowd going for a burst of energy!</p>
          </Col>
          <Col className="col-12 mt-5">
            <p><img src={live} className="img-fluid" alt="Dirteez - Live" /></p>
            <p className="mt-4"><strong>Wild Cat Lou</strong> (guitar and vocals) and <strong>Clint Lhazar</strong> (vocals) have been there since the beginning. They are now accompanied by <strong>Tchoupy</strong> (bass) and <strong>Jeremy</strong> (drums).</p>
            <p><strong>For better or worse, they are often referred to as the "Marseille Cramps"...</strong></p>
          </Col>
          <Col className="col-12">
            <div className="video-wrapper">
              <iframe title="youtube" src="https://www.youtube.com/embed/KKcbNd-YU1M" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Col>
        </Row>
      </Container>
  </Layout>
)

export default IndexPage
